import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { PageLayout } from "../components/layout/layout";
import Container from "../components/container/container";
import TireCard from "../components/productCard/tireCard/tireCard";

//CSS
import * as StyledComponents from "../pageStyles/suretrac.module.scss";

// Import querry string library
var qs = require("qs");

// markup
const SuretracPage = () => {
  const [AvalibleSizes, setAvalibleSizes] = useState([]);

  useEffect(() => {
    tires();
  }, []);

  async function tires() {
    const query = qs.stringify({
      _where: [{ brand: "SURETRAC" }],
    });
    await fetch(`https://www.wheels-api.com/tires?${query}&_limit=-1`)
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result);
          setAvalibleSizes(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  return (
    <PageLayout>
      <Helmet>
        <title>SURETRAC | CANADA OFFROAD</title>
      </Helmet>
      <Container>
        <div className={StyledComponents.main}>
          {AvalibleSizes.map((item) => {
            return (
              <>
                <TireCard
                  image={item.image}
                  sku={item.sku}
                  brand={item.brand}
                  model={item.model}
                  price={item.price}
                  width={item.sectionWidth}
                  aspect={item.aspectRatio}
                  diam={item.diameter}
                  season={item.season}
                />
              </>
            );
          })}
        </div>
      </Container>
    </PageLayout>
  );
};

export default SuretracPage;
